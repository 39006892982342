.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 140vh; /* to be increased in case of more projects */
  overflow-x: hidden;
  padding: 2rem;
}

.projects--header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem;
}

.projects--header h1 {
  margin-bottom: 40px;
  font-size: 3.5rem;
  font-family: var(--primaryFont);
}

.projects--body {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 400px; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* border: 2px solid blue; */
}

.projects--bodyContainer {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  row-gap: 100px;
  /* gap: 4.5rem; */
  width: 200%;
  /* border: 2px solid green; */
}

.projects--viewAll {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.projects--viewAll a button {
  outline: none;
  border: none;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: inherit;
  border-radius: 45px;
  font-size: 1.05rem;
  font-family: var(--primaryFont);
  font-weight: 500;
  padding-left: 1.5rem;
  cursor: pointer;
}

@media (min-width: 992px) and (max-width: 1380px) {
}

@media screen and (max-width: 992px) {
  .projects--bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 201%;
  }
}

@media screen and (max-width: 800px) {
  .projects--header h1 {
    font-size: 3rem;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .projects--header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .projects--viewAll {
    position: relative;
    margin-top: 4rem;
  }
  .projects--viewAll a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 475px) {
  .projects--bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 172%;
  }
}

@media screen and (max-width: 440px) {
  .projects--bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
