.singleProject {
  box-shadow: 4px 4px 8px rgba(36, 3, 3, 0.4);
  width: 90%;
  height: 640px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.4rem 2rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid rgb(0, 217, 255);
}

.projectContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.projectContent > h2 {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 600;
  font-size: 1.525rem;
  line-height: 110%;
  text-align: center;
}

.projectContent > img {
  width: 100%;
  height: 60%;
  transition: opacity 0.7s 0.3s;
  border-radius: 20px;
}

/* .singleProject:hover img{
    opacity: 0.7;
} */

.project--showcaseBtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.project--showcaseBtn > a {
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.5s 0.3s;
}

/* .project--showcaseBtn a:nth-child(2) {
    transform: translateX(-140px) scale(1.1) !important;
} */

.singleProject .project--desc {
  /* position: absolute; */
  width: 100%;
  height: auto;
  left: 0;
  bottom: 20px;
  /* transform: translate(-110%,40%); */
  padding: 18px;
  border-radius: 20px 20px;
  /* transition: transform 0.9s; */
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--primaryFont);
}

/* .singleProject .project--desc{
    transform: translate(-2%,40%);
} */

.singleProject .project--lang {
  /* position: absolute; */
  bottom: 20px;
  /* right: 0; */
  width: 100%;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  gap: 0.4rem;
  flex-direction: row;
  border-radius: 10px 10px;
  /* transform: translateX(100%); */
  transition: transform 0.5s 0.3s;
  padding: 0.725rem;
  margin-bottom: 20px;
}

/* .singleProject .project--lang{
    transform: translateX(0);
} */

.project--lang > span {
  font-family: var(--primaryFont);
  font-weight: 500;
  word-break: break-word;
  line-height: 100%;
}
